<template>
  <div class="wrap">
    <div class="wrap-header">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/declaration' }"><a>Pre-declaration </a></el-breadcrumb-item>
        <el-breadcrumb-item>Query Result</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="wrap-header-btn">
        <el-button type="primary" plain size="small" class="public-fonts" @click="check()">Check Submit Status</el-button>
        <el-button type="primary" plain size="small" class="public-fonts" @click="create()">Create</el-button>
        <el-button type="primary" plain size="small" class="public-button-color public-fonts" @click="query()">Query</el-button>
      </div>
    </div>
    <div class="wrap-content">
      <el-table
        :data="this.$store.state.queryResultDeclaration.datas"
        @row-dblclick="viewDeclaration"
        class="content-table">
        <el-table-column
          prop="cargoType"
          label="Cargo Type"
          label-class-name="title">
          <template slot-scope="scope">
            <span>{{ judgeCargoType(scope.row.cargoType) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="uld"
          label="ULD#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="mawb"
          label="MAWB#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="dest"
          label="Dest."
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="flight"
          label="Flight#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="flightDate"
          label="Flight Date"
          label-class-name="title">
        </el-table-column>
         <el-table-column
          prop="createdBy"
          label="Created By"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="submitTime"
          label="Submit Time"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          label-class-name="title">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 'success'">SUCCESS</span>
            <span v-if="scope.row.status === 'fail'">FAIL</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="rclAvailable"
          label="RCL Available?"
          label-class-name="title">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="this.$store.state.queryResultDeclaration.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="this.$store.state.queryResultDeclaration.pageSize"
          layout="total, prev, slot, next, sizes, jumper, ->"
          :total="this.$store.state.queryResultDeclaration.totalNum">
          <span class="on-pager">{{ this.$store.state.queryResultDeclaration.currentPage }}</span>
      </el-pagination>
    </div>
    <el-drawer
      title="Query Criteria"
      :visible.sync="querydrawer"
      :show-close="false"
      :direction="direction"
      :size="332">
      <Query @query-declaration-drawer="changeQuerydrawer"></Query>
    </el-drawer>
  </div>
</template>

<script>
import Query from '../declaration/query.vue'
import { queryPreDeclaration } from '@/api/declaration/declaration-api.js'
import { getOfficeCode } from '@/utils/auth'

export default {
  name: 'query-result',
  data () {
    return {
      querydrawer: false, // 查询抽屉
      direction: 'rtl', // 查询抽屉弹出的方向
      title: null,
      fromPath: '/declaration'
    }
  },
  components: {
    Query
  },
  computed: {
    judgeCargoType () {
      return val => {
        if (val === 'BUK') return 'BULK SHIPMENT'
        if (val === 'MIX') return 'MIX-LOAD SHIPMENT'
        if (val === 'PPK') return 'PRE-PACK SHIPMENT'
      }
    }
  },
  mounted () {
    if (this.fromPath === '/declaration/query-result') this.init()
    else if (this.$route.path === '/declaration/query-result' && this.fromPath === '/') this.init()
  },
  methods: {
    init () {
      const obj = {
        data: {
          inputULD: this.$route.query.inputULD,
          awb: this.$route.query.awb,
          dest: this.$route.query.dest,
          flightNo: this.$route.query.flight,
          fromDate: this.$route.query.fromDate,
          toDate: this.$route.query.toDate,
          rclAvailable: this.$route.query.rclAvailable
        },
        office: getOfficeCode(),
        pageNum: 1,
        pageSize: 10
      }
      queryPreDeclaration(obj).then(res => {
        if (res.data.CODE === 200) {
          const result = res.data.DATA
          this.$store.dispatch('changeQueryResultDeclaration', result)
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'query successful!',
            type: 'success',
            showClose: true,
            duration: 8000,
            offset: 120
          })
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    sizeChange (val) {
      const obj = {
        data: {
          inputULD: this.$route.query.uld,
          awb: this.$route.query.awb,
          dest: this.$route.query.dest,
          flightNo: this.$route.query.flight,
          fromDate: this.$route.query.fromDate,
          toDate: this.$route.query.toDate,
          rclAvailable: this.$route.query.rclAvailable
        },
        office: getOfficeCode(),
        pageNum: 1,
        pageSize: val
      }
      queryPreDeclaration(obj).then(res => {
        if (res.data.CODE === 200) {
          const result = res.data.DATA
          this.$store.dispatch('changeQueryResultDeclaration', result)
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    currentChange (val) {
      const obj = {
        data: {
          inputULD: this.$route.query.uld,
          awb: this.$route.query.awb,
          dest: this.$route.query.dest,
          flightNo: this.$route.query.flight,
          fromDate: this.$route.query.fromDate,
          toDate: this.$route.query.toDate,
          rclAvailable: this.$route.query.rclAvailable
        },
        office: getOfficeCode(),
        pageNum: val,
        pageSize: this.$store.state.queryResultDeclaration.pageSize
      }
      queryPreDeclaration(obj).then(res => {
        if (res.data.CODE === 200) {
          const result = res.data.DATA
          this.$store.dispatch('changeQueryResultDeclaration', result)
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    query () {
      this.querydrawer = true
    },
    changeQuerydrawer (data) {
      this.querydrawer = data.close
    },
    check () {
      this.$router.push({
        path: '/declaration/check'
      })
    },
    create () {
      this.$router.push({
        path: '/declaration/create',
        query: {
          type: 'Create'
        }
      })
    },
    viewDeclaration (row) {
      this.$router.push({
        path: '/declaration/create',
        query: {
          type: 'View',
          preDeclarationId: row.preDeclarationId
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      vm.fromPath = from.path
    })
  }
}
</script>

<style lang="scss" scoped>
$bg1:#1890ff;
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-content{
    padding: 5px 24px 24px;
    min-height: 72vh;
    .content-table{
      width: 100%;
      border-radius: 8px;
    }
    ::v-deep {
       .title{
        color: #000;
        font-size: 16px;
      }
    }
  }
}
</style>
