<template>
  <div class="wrap-query">
    <el-form ref="form" :model="form" :label-position="labelPosition" label-width="80px">
      <el-form-item label="ULD#">
        <el-input v-model="form.uld"></el-input>
      </el-form-item>
      <el-form-item label="MAWB#">
        <el-input v-model="form.mawb"></el-input>
      </el-form-item>
      <el-form-item label="Dest.">
        <el-input v-model="form.dest"></el-input>
      </el-form-item>
      <el-form-item label="Flight#">
        <el-input v-model="form.flight"></el-input>
      </el-form-item>
      <el-form-item label="Flight Date">
        <el-date-picker
          v-model="form.flightDate"
          type="daterange"
          start-placeholder="From Date"
          end-placeholder="To Date"
          :default-time="['00:00:00', '23:59:59']"
          format="yyyy - MM - dd"
          value-format="yyyy-MM-dd"
          :editable="false">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="RCL Available?">
        <el-select v-model="form.rclAvailable" clearable>
          <el-option
            v-for="item in rclAvailableOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <footer class="submit-button">
      <el-button size="small" class="public-fonts" @click="queryReset()">Reset</el-button>
      <el-button size="small" type="primary" class="public-fonts" @click="querySubmit()">Query</el-button>
    </footer>
  </div>
</template>

<script>
import { queryPreDeclaration } from '@/api/declaration/declaration-api.js'
import { getOfficeCode } from '@/utils/auth'

export default {
  name: 'query',
  data () {
    return {
      form: {
        uld: null,
        mawb: null,
        dest: null,
        flight: null,
        flightDate: null,
        rclAvailable: null
      },
      labelPosition: 'top',
      rclAvailableOptions: [
        {
          value: 'Yes',
          label: 'Yes'
        },
        {
          value: 'No',
          label: 'No'
        }
      ]
    }
  },
  methods: {
    queryReset () {
      this.form.uld = null
      this.form.mawb = null
      this.form.dest = null
      this.form.flight = null
      this.form.flightDate = null
      this.form.rclAvailable = null
    },
    querySubmit () {
      const obj = {
        data: {
          inputULD: this.form.uld ? this.form.uld.toUpperCase() : null,
          awb: this.form.mawb ? this.form.mawb.toUpperCase() : null,
          dest: this.form.dest ? this.form.dest.toUpperCase() : null,
          flightNo: this.form.flight ? this.form.flight.toUpperCase() : null,
          fromDate: this.form.flightDate ? this.form.flightDate[0] : null,
          toDate: this.form.flightDate ? this.form.flightDate[1] : null,
          rclAvailable: this.form.rclAvailable
        },
        office: getOfficeCode(),
        pageNum: 1,
        pageSize: 10
      }
      queryPreDeclaration(obj).then(res => {
        if (res.data.CODE === 200) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Query Successfully!',
            type: 'success',
            showClose: true,
            duration: 8000,
            offset: 120
          })
          this.$store.dispatch('changeQueryResultDeclaration', res.data.DATA)
          if (this.$route.path !== '/declaration/query-result') {
            this.$router.push({ path: '/declaration/query-result', query: obj.data })
          } else {
            this.$router.replace({ query: obj.data })
          }
          this.$emit('query-declaration-drawer', { close: false })
          this.form.uld = null
          this.form.mawb = null
          this.form.dest = null
          this.form.flight = null
          this.form.flightDate = null
          this.form.rclAvailable = null
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-query {
  ::v-deep{
    .el-date-editor.el-input, .el-date-editor.el-input__inner{
      width: 100% !important;
    }
    .el-select {
      width: 100%;
    }
    .el-form-item{
      margin-bottom: 10px;
    }
  }
}
</style>
