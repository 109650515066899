import request from '../../utils/request'

// 模糊搜索truckerName
export function getTruckerName (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/find/TruckerName',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj.data,
    loading: obj.loading
  })
}

// create PreDeclaration
export function createPreDeclaration (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/create/pre-declaration',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj,
    loading: true
  })
}

// update PreDeclaration
export function updatePreDeclaration (obj) {
  return request({
    method: 'put',
    url: `/limosa-01/service/${obj.id}/find`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj.data,
    loading: true
  })
}

// query PreDeclaration
export function queryPreDeclaration (obj) {
  return request({
    method: 'post',
    url: `/limosa-01/service/${obj.office}/findAll/Pre-declaration?pageNum=${obj.pageNum}&pageSize=${obj.pageSize}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj.data,
    loading: true
  })
}

// query PreDeclaration ById
export function queryPreDeclarationById (id) {
  return request({
    method: 'get',
    url: `/limosa-01/service/${id}/find?ediType=Pre-declaration`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: true
  })
}

// export order
export function exportOrderPreDeclaration (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/submit/export/order',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj,
    loading: true
  })
}

// 时间查询
export function queryCheckSubmitStatusByTIme (obj) {
  return request({
    method: 'get',
    url: `/limosa-01/service/find by time?officeCode=${obj.officeCode}&timeGap=${obj.timeGap}&pageNum=${obj.pageNum}&pageSize=${obj.pageSize}&mawb=${obj.mawb}&uld=${obj.uld}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: true
  })
}

// destination模糊查询
export function queryDeclaration (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/queryDest',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj,
    loading: false
  })
}

// submit pre-declaration
export function submitPreDeclaration (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/submit/preDeclaration',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj,
    loading: true
  })
}

// download rcl
export function downloadFileRCL (obj) {
  return request({
    method: 'get',
    url: '/limosa-01/service/DownLoadFile/' + obj,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: true,
    responseType: 'blob'
  })
}
